// StoryPage.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext'; // Access current user
import { db } from './firebase';        // Firestore instance
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; // For generating a unique storyId
import { useTheme } from '@mui/material/styles';

function StoryPage() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const theme = useTheme();
  const storyBackgroundUrl = theme.wallpaper;

  // --------------------- SUGGESTION ARRAYS ---------------------
  const titleSuggestions = [
    "A Brave Adventure",
    "Midnight in the Woods",
    "The Lost City",
    "Journey into Twilight",
    "Shadows of the Past",
  ];
  const timeSuggestions = [
    "medieval times",
    "the future",
    "the roaring 1920s",
    "prehistoric era",
    "the year 3000",
  ];
  const placeSuggestions = [
    "a small village",
    "outer space",
    "an underwater city",
    "the Sahara Desert",
    "a bustling metropolis",
  ];
  const characterNameSuggestions = [
    "Alice",
    "John",
    "Kai",
    "Maria",
    "Luca",
  ];
  const protagonistSuggestions = [
    "a courageous knight",
    "a curious child",
    "an eccentric detective",
    "a timid librarian",
    "a fearless astronaut",
  ];
  const mainStorylineSuggestions = [
    "A quest to find the lost treasure",
    "Stopping a sinister villain",
    "A journey of self-discovery",
    "Reuniting two estranged worlds",
    "Unraveling a family secret",
  ];
  const storyLengthSuggestions = [
    "300-500",
    "500-700",
    "800-1000",
    "250-400",
    "1000-1200",
  ];

  // --------------------- INDEX STATES (for cycling suggestions) ---------------------
  const [titleIndex, setTitleIndex] = useState(0);
  const [timeIndex, setTimeIndex] = useState(0);
  const [placeIndex, setPlaceIndex] = useState(0);
  const [characterNameIndex, setCharacterNameIndex] = useState(0);
  const [protagonistIndex, setProtagonistIndex] = useState(0);
  const [mainStorylineIndex, setMainStorylineIndex] = useState(0);
  const [storyLengthIndex, setStoryLengthIndex] = useState(0);

  // --------------------- FIELD STATES ---------------------
  const [storyLanguage, setStoryLanguage] = useState(
    () => localStorage.getItem('storyLanguage') || ''
  );
  const [numDays, setNumDays] = useState(() => {
    const savedNumDays = localStorage.getItem('numDays');
    return savedNumDays ? parseInt(savedNumDays, 10) : '';
  });
  const [numDaysError, setNumDaysError] = useState(''); // New state for error handling
  const [languageDifficulty, setLanguageDifficulty] = useState(
    () => localStorage.getItem('languageDifficulty') || ''
  );
  const [storyLength, setStoryLength] = useState(
    () => localStorage.getItem('storyLength') || ''
  );
  const [title, setTitle] = useState(
    () => localStorage.getItem('title') || ''
  );
  const [storyType, setStoryType] = useState(
    () => localStorage.getItem('storyType') || ''
  );
  const [time, setTime] = useState(
    () => localStorage.getItem('time') || ''
  );
  const [place, setPlace] = useState(
    () => localStorage.getItem('place') || ''
  );
  const [characterName, setCharacterName] = useState(
    () => localStorage.getItem('characterName') || ''
  );
  const [protagonist, setProtagonist] = useState(
    () => localStorage.getItem('protagonist') || ''
  );
  const [mainStoryline, setMainStoryline] = useState(
    () => localStorage.getItem('mainStoryline') || ''
  );

  // --------------------- LOADING / ERROR STATES ---------------------
  const [loading, setLoading] = useState(false);
  const [currentDay, setCurrentDay] = useState(0);
  const [error, setError] = useState('');

  // --------------------- NEW: Generate Image? ---------------------
  const [generateImage, setGenerateImage] = useState(false);

  // --------------------- BACKEND URL ---------------------
  const backendUrl =
    process.env.REACT_APP_BACKEND_URL ||
    'https://ibotstorybackend-f6e0c4f9h9bkbef8.eastus2-01.azurewebsites.net';

  // --------------------- Local Storage Effects ---------------------
  useEffect(() => {
    localStorage.setItem('storyLanguage', storyLanguage);
  }, [storyLanguage]);

  useEffect(() => {
    localStorage.setItem('numDays', numDays);
  }, [numDays]);

  useEffect(() => {
    localStorage.setItem('languageDifficulty', languageDifficulty);
  }, [languageDifficulty]);

  useEffect(() => {
    localStorage.setItem('storyLength', storyLength);
  }, [storyLength]);

  useEffect(() => {
    localStorage.setItem('title', title);
  }, [title]);

  useEffect(() => {
    localStorage.setItem('storyType', storyType);
  }, [storyType]);

  useEffect(() => {
    localStorage.setItem('time', time);
  }, [time]);

  useEffect(() => {
    localStorage.setItem('place', place);
  }, [place]);

  useEffect(() => {
    localStorage.setItem('characterName', characterName);
  }, [characterName]);

  useEffect(() => {
    localStorage.setItem('protagonist', protagonist);
  }, [protagonist]);

  useEffect(() => {
    localStorage.setItem('mainStoryline', mainStoryline);
  }, [mainStoryline]);

  // --------------------- KEYDOWN FOR SUGGESTIONS ---------------------
  const handleSuggestionKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      switch (field) {
        case 'title':
          setTitle(titleSuggestions[titleIndex]);
          setTitleIndex((prev) => (prev + 1) % titleSuggestions.length);
          break;
        case 'time':
          setTime(timeSuggestions[timeIndex]);
          setTimeIndex((prev) => (prev + 1) % timeSuggestions.length);
          break;
        case 'place':
          setPlace(placeSuggestions[placeIndex]);
          setPlaceIndex((prev) => (prev + 1) % placeSuggestions.length);
          break;
        case 'characterName':
          setCharacterName(characterNameSuggestions[characterNameIndex]);
          setCharacterNameIndex((prev) => (prev + 1) % characterNameSuggestions.length);
          break;
        case 'protagonist':
          setProtagonist(protagonistSuggestions[protagonistIndex]);
          setProtagonistIndex((prev) => (prev + 1) % protagonistSuggestions.length);
          break;
        case 'mainStoryline':
          setMainStoryline(mainStorylineSuggestions[mainStorylineIndex]);
          setMainStorylineIndex((prev) => (prev + 1) % mainStorylineSuggestions.length);
          break;
        case 'storyLength':
          setStoryLength(storyLengthSuggestions[storyLengthIndex]);
          setStoryLengthIndex((prev) => (prev + 1) % storyLengthSuggestions.length);
          break;
        default:
          break;
      }
    }
  };

  // --------------------- STORY LENGTH PARSER ---------------------
  const getLengthInstruction = (input) => {
    if (!input.trim()) {
      return "Keep it around 300 words.";
    }
    if (input.includes("-")) {
      const [minRaw, maxRaw] = input.split("-").map((str) => str.trim());
      const minVal = parseInt(minRaw, 10);
      const maxVal = parseInt(maxRaw, 10);
      if (!isNaN(minVal) && !isNaN(maxVal)) {
        return `Try to keep it between ${minVal} and ${maxVal} words.`;
      } else {
        return `Try to keep it around ${input} words.`;
      }
    }
    return `Try to keep it around ${input.trim()} words.`;
  };

  // --------------------- Input Validation for Number of Days ---------------------
  const handleNumDaysChange = (e) => {
    const value = e.target.value;
    // Allow empty string to let the user clear the input
    if (value === '') {
      setNumDays('');
      setNumDaysError('');
      return;
    }

    const parsedValue = parseInt(value, 10);

    if (isNaN(parsedValue)) {
      setNumDaysError('Please enter a valid number.');
    } else if (parsedValue < 1) {
      setNumDaysError('Number of days cannot be less than 1.');
    } else if (parsedValue > 10) {
      setNumDaysError('Maximum number of days is 10.');
      setNumDays(10);
    } else {
      setNumDays(parsedValue);
      setNumDaysError('');
    }
  };

  // --------------------- STORY GENERATION ---------------------
  const handleNextStep = (e) => {
    e.preventDefault();

    // Validate numDays before proceeding
    if (numDays === '' || numDays < 1 || numDays > 10) {
      setNumDaysError('Please enter a valid number of days (1-10).');
      return;
    }

    navigate('/voice-selection', {
      state: {
        storyLanguage,
        numDays,
        languageDifficulty,
        storyLength,
        title,
        storyType,
        time,
        place,
        characterName,
        protagonist,
        mainStoryline,
        generateImage,
      },
    });
  };  

  return (
    <Box
      sx={{
        background: `url(${storyBackgroundUrl}) center center / cover no-repeat fixed`,
        minHeight: '100vh',
        mt: 0,
      }}
    >
      <Container
        maxWidth="md" // Reduced maxWidth for a smaller form
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          mt={2}
          sx={{
            width: '100%', // Full width within the container
            padding: { xs: '10px', sm: '20px', md: '30px' }, // Adjusted padding for smaller height
          }}
        >
          <div className="Subtitle" style={{ textAlign: 'center', marginBottom: '20px' }}>
            Generate a Story Series
          </div>

          <Box component="form" onSubmit={handleNextStep} mb={2}>
            {/* BASIC OPTIONS */}
            <Box
              p={2} // Reduced padding
              mb={3} // Reduced margin-bottom
              borderRadius={2}
              sx={{ backgroundColor: '#ffffff', border: '1px solid #e0e0e0', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                Basic Options
              </Typography>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2} // Reduced gap
              >
                <TextField
                  fullWidth
                  select
                  label="Story Language"
                  value={storyLanguage}
                  onChange={(e) => setStoryLanguage(e.target.value)}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Spanish">Spanish</MenuItem>
                  <MenuItem value="Chinese">Chinese</MenuItem>
                  <MenuItem value="French">French</MenuItem>
                  <MenuItem value="German">German</MenuItem>
                  <MenuItem value="Japanese">Japanese</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Number of Days"
                  type="number"
                  value={numDays}
                  onChange={handleNumDaysChange} // Updated handler
                  variant="outlined"
                  margin="dense" // Reduced margin
                  inputProps={{ min: 1, max: 10 }} // Added min and max attributes
                  error={Boolean(numDaysError)} // Show error state
                  helperText={numDaysError} // Display error message
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                />
              </Box>
            </Box>

            {/* OPTIONAL FIELDS */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: '20px', fontWeight: 'bold' }}>
              Optional:
            </Typography>

            {/* STORY SETUP */}
            <Box
              p={2} // Reduced padding
              mb={3} // Reduced margin-bottom
              borderRadius={2}
              sx={{
                backgroundColor: '#ffffff',
                border: '1px solid #e0e0e0',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                Story Setup
              </Typography>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2} mb={2}>
                <TextField
                  fullWidth
                  label="Title"
                  placeholder="e.g., The Brave Adventurer"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onKeyDown={(e) => handleSuggestionKeyDown(e, 'title')}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                />
                <TextField
                  fullWidth
                  select
                  label="Story Type"
                  value={storyType}
                  onChange={(e) => setStoryType(e.target.value)}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Adventure">Adventure</MenuItem>
                  <MenuItem value="Mystery">Mystery</MenuItem>
                  <MenuItem value="Romance">Romance</MenuItem>
                  <MenuItem value="Fantasy">Fantasy</MenuItem>
                  <MenuItem value="Science Fiction">Science Fiction</MenuItem>
                  <MenuItem value="Horror">Horror</MenuItem>
                </TextField>
              </Box>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                <TextField
                  fullWidth
                  label="Time"
                  placeholder="e.g., medieval times, the future"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  onKeyDown={(e) => handleSuggestionKeyDown(e, 'time')}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                />
                <TextField
                  fullWidth
                  label="Place"
                  placeholder="e.g., a small village, outer space"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                  onKeyDown={(e) => handleSuggestionKeyDown(e, 'place')}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                />
              </Box>
            </Box>

            {/* MAIN CHARACTER */}
            <Box
              p={2} // Reduced padding
              mb={3} // Reduced margin-bottom
              borderRadius={2}
              sx={{
                backgroundColor: '#ffffff',
                border: '1px solid #e0e0e0',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                Main Character
              </Typography>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                <TextField
                  fullWidth
                  label="Character Name"
                  placeholder="e.g., Alice, John"
                  value={characterName}
                  onChange={(e) => setCharacterName(e.target.value)}
                  onKeyDown={(e) => handleSuggestionKeyDown(e, 'characterName')}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                />
                <TextField
                  fullWidth
                  label="Protagonist Characteristics"
                  placeholder="e.g., a courageous knight, a curious child"
                  value={protagonist}
                  onChange={(e) => setProtagonist(e.target.value)}
                  onKeyDown={(e) => handleSuggestionKeyDown(e, 'protagonist')}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                />
              </Box>
            </Box>

            {/* MAIN STORYLINE */}
            <Box
              p={2} // Reduced padding
              mb={3} // Reduced margin-bottom
              borderRadius={2}
              sx={{
                backgroundColor: '#ffffff',
                border: '1px solid #e0e0e0',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                Main Storyline
              </Typography>
              <TextField
                fullWidth
                label="Main Storyline"
                placeholder="e.g., A quest to find the lost treasure"
                value={mainStoryline}
                onChange={(e) => setMainStoryline(e.target.value)}
                onKeyDown={(e) => handleSuggestionKeyDown(e, 'mainStoryline')}
                variant="outlined"
                margin="dense" // Reduced margin
                sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
              />
            </Box>

            {/* DIFFICULTY & STORY LENGTH */}
            <Box
              p={2} // Reduced padding
              mb={3} // Reduced margin-bottom
              borderRadius={2}
              sx={{
                backgroundColor: '#ffffff',
                border: '1px solid #e0e0e0',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                Difficulty & Story Length
              </Typography>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                <TextField
                  fullWidth
                  select
                  label="Language Difficulty"
                  value={languageDifficulty}
                  onChange={(e) => setLanguageDifficulty(e.target.value)}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Super Easy (use the most basic 500 vocabulary in English)">Super Easy</MenuItem>
                  <MenuItem value="Easy">Easy</MenuItem>
                  <MenuItem value="Intermediate">Intermediate</MenuItem>
                  <MenuItem value="Advanced">Advanced</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Story Length"
                  placeholder="e.g., 300-500"
                  value={storyLength}
                  onChange={(e) => setStoryLength(e.target.value)}
                  onKeyDown={(e) => handleSuggestionKeyDown(e, 'storyLength')}
                  variant="outlined"
                  margin="dense" // Reduced margin
                  sx={{ backgroundColor: '#f9f9f9', borderRadius: 1 }}
                />
              </Box>
            </Box>

            {/* NEW: Checkbox for generating an image with DALL·E */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={generateImage}
                  onChange={(e) => setGenerateImage(e.target.checked)}
                  color="primary"
                />
              }
              label="Generate Story Image (DALL·E)"
              sx={{ marginBottom: '20px' }}
            />

            {/* Enlarged "Next Step" Button */}
            <Box display="flex" justifyContent="center">
              <Button
                type="submit" // Changed to submit for form submission
                variant="contained"
                color="primary"
                className="NextStepButton" // Added class for additional styling
                sx={{
                  width: { xs: '100%', sm: '60%', md: '50%' }, // Responsive width
                  height: '60px', // Increased height
                  fontSize: '20px', // Increased font size
                  borderRadius: '30px', // More rounded corners
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                Next Step
              </Button>
            </Box>
          </Box>
          {error && (
            <Box mt={2} p={2} bgcolor="#ffebee" borderRadius={2}>
              <Typography color="error" variant="body1">
                {error}
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default StoryPage;

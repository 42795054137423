import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles'; // <-- Import this

const HistoryLink = styled(Link)(({ theme }) => ({
  width: '10vw',
  height: '10vw',
  bottom: '40vh',
  textAlign: 'center',
  fontSize: '25px',
  transform: 'rotate(-90deg)',
  background: 'transparent',
  color: '#fff',
  position: 'fixed',
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 4,
  transition: '0.4s ease-in-out',
  borderRadius: '0 0 100px 100px',
  textDecoration: 'none',
  cursor: 'pointer',

  '&:hover': {
    background: theme.palette.primary.main, // use the theme color
    textDecoration: 'none',
  },
}));
const BackgroundBox = styled(Box)(({ theme }) => ({
  // We'll set the background below
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  position: 'absolute',
}));

const StartPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const startBackgroundUrl = theme.startPageBackground;

  const handleStartClick = () => {
    navigate('/story');
  };

  return (
    <BackgroundBox className='Background'sx={{
      backgroundImage: `url(${startBackgroundUrl})`,
    }}>
      <Container maxWidth="sm">
        <div className='Title'>
          Customize Your Own Series!
        </div>
        <Button
          className="Button"
          variant="contained"
          onClick={handleStartClick}
          size="large"
        >
          Start Personalized Story Creating
        </Button>
      </Container>
      {/* History Button */}
      {/* Remove inline styling; just give it an id or class that we'll style via CSS */}
      <HistoryLink  to="/history" id="history">
        History
      </HistoryLink>
    </BackgroundBox>
  );
};

export default StartPage;

// src/GeneratedStoryPage.js

import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  List,
  ListItem,
  Paper,
  IconButton,
  Slider,
  Button,
  CircularProgress,
  Divider, // Imported Divider
} from '@mui/material';
import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { db } from './firebase';
import { doc, getDoc, collection, getDocs, query, orderBy } from 'firebase/firestore';
import { useAuth } from './AuthContext';

// Color mapping for steps based on action
const stepColorMapping = {
  READ_ALOUD: '#1976d2',       // MUI Primary Blue
  SHADOW: '#ffa726',           // MUI Warning Orange
  TRANSLATE_VOICE: '#388e3c',  // MUI Success Green
};

function GeneratedStoryPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { storyId, title } = location.state || {};

  const { currentUser } = useAuth(); // Ensure you have access to currentUser

  const [storyData, setStoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Playback states
  const [isPlaying, setIsPlaying] = useState(false);
  const [isShadowPlaying, setIsShadowPlaying] = useState(false);
  const [shadowPlayIndex, setShadowPlayIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false); // For Pause/Resume
  const [progress, setProgress] = useState(0); // Playback progress
  const [duration, setDuration] = useState(0); // Total duration
  const [currentTime, setCurrentTime] = useState(0); // Current time
  const [isSeeking, setIsSeeking] = useState(false); // Whether the user is seeking

  const shadowTimeoutRef = useRef(null); // To hold timeout ID for pauses
  const audioRef = useRef(null); // Ref for current audio element

  const stepsOrder = storyData?.metadata?.stepsOrder || []; 

  // Define currentDayIndex and setCurrentDayIndex
  const [currentDayIndex, setCurrentDayIndex] = useState(0);

  // Helper function to format time
  const formatTime = (time) => {
    if (isNaN(time)) return '00:00';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`;
  };

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      if (shadowTimeoutRef.current) {
        clearTimeout(shadowTimeoutRef.current);
        shadowTimeoutRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!storyId || !currentUser) {
      setError('Missing story configuration. Please go back and complete the story setup.');
      setLoading(false);
      return;
    }

    const fetchStoryData = async () => {
      try {
        // Reference to the story document
        const storyDocRef = doc(db, 'users', currentUser.uid, 'generatedStories', storyId);
        const storyDocSnap = await getDoc(storyDocRef);

        if (!storyDocSnap.exists()) {
          setError('Story not found.');
          setLoading(false);
          return;
        }

        const story = storyDocSnap.data();

        // Fetch days with ordering
        const daysCollectionRef = collection(storyDocRef, 'days');
        const daysQuery = query(daysCollectionRef, orderBy('day', 'asc')); // Order by 'day' field ascending
        const daysSnapshot = await getDocs(daysQuery);
        const days = daysSnapshot.docs.map(doc => doc.data());

        // Optionally, sort locally if needed
        // days.sort((a, b) => a.day - b.day);

        // Fetch metadata/main document
        const metadataDocRef = doc(storyDocRef, 'metadata', 'main');
        const metadataDocSnap = await getDoc(metadataDocRef);
        let metadata = null;
        if (metadataDocSnap.exists()) {
          metadata = metadataDocSnap.data();
        } else {
          console.warn('Metadata document does not exist.');
          setError('Metadata for this story is missing.');
          setLoading(false);
          return;
        }

        setStoryData({
          title: story.title,
          createdAt: story.createdAt ? story.createdAt.toDate() : null,
          days,
          metadata,
        });

        // Initialize currentDayIndex to 0 if not already set
        if (days.length > 0) {
          setCurrentDayIndex(0);
        }
      } catch (err) {
        console.error('Error fetching story data:', err);
        setError('Error fetching story data.');
      } finally {
        setLoading(false);
      }
    };

    fetchStoryData();
  }, [storyId, currentUser]);

  // Function to calculate pause duration based on sentence length
  const calculatePauseDuration = (sentence) => {
    const words = sentence.trim().split(/\s+/).length;
    const basePause = 1000; // Base pause in ms
    const extraPause = words * 100; // 100ms per word
    return basePause + extraPause; // Total pause duration
  };

  // Function to handle progress update
  const handleTimeUpdate = () => {
    if (audioRef.current && audioRef.current.duration) {
      const currentProgress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(currentProgress);
      if (!isSeeking) {
        setCurrentTime(audioRef.current.currentTime);
      }
    }
  };

  // Function to play Shadow Reading starting from a specific index
  const playShadowReading = (shadowUrls, shadowSentences, startIndex = 0) => {
    if (!shadowUrls || !Array.isArray(shadowUrls) || shadowUrls.length === 0) {
      alert('No Shadow Reading audio available for this day.');
      return;
    }

    if (!shadowSentences || !Array.isArray(shadowSentences)) {
      alert('No Shadow Reading sentences available.');
      return;
    }

    const minLength = Math.min(shadowUrls.length, shadowSentences.length);
    if (shadowUrls.length !== shadowSentences.length) {
      console.warn(
        'Number of Shadow Reading audio URLs and sentences do not match. Proceeding with the minimum length.',
        shadowUrls.length,
        shadowSentences.length
      );
    }

    setIsShadowPlaying(true);
    setShadowPlayIndex(startIndex);

    const playNextSentence = (index) => {
      if (index >= minLength) {
        // Finished all sentences
        setIsShadowPlaying(false);
        setIsPlaying(false);
        setProgress(0); // Reset progress
        setCurrentTime(0);
        setDuration(0);
        return;
      }

      const shadowUrl = shadowUrls[index];
      const sentence = shadowSentences[index] || '';
      const audio = new Audio(shadowUrl);
      audioRef.current = audio;

      // Reset progress
      setProgress(0);
      setCurrentTime(0);
      setDuration(0);

      // Add event listeners
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', () => {
        setDuration(audio.duration);
      });

      audio.play().catch((err) => {
        console.error('Error playing Shadow Reading audio:', err);
        setError('Error playing Shadow Reading audio.');
        setIsShadowPlaying(false);
        setIsPlaying(false);
        setProgress(0); // Reset progress
        setCurrentTime(0);
        setDuration(0);
      });

      audio.onended = () => {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('loadedmetadata', () => {});
        // Calculate pause duration based on sentence length
        const pauseDuration = calculatePauseDuration(sentence);

        // Proceed to next sentence after pause
        shadowTimeoutRef.current = setTimeout(() => {
          setShadowPlayIndex(index + 1);
          playNextSentence(index + 1);
        }, pauseDuration);
      };
    };

    // Start playing from the startIndex
    playNextSentence(startIndex);
  };

  // Handler to play all audio steps
  const handlePlayAll = () => {
    // Stop any existing audio
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
    if (shadowTimeoutRef.current) {
      clearTimeout(shadowTimeoutRef.current);
      shadowTimeoutRef.current = null;
    }

    // Reset pause state and progress
    setIsPaused(false);
    setProgress(0);
    setCurrentTime(0);
    setDuration(0);

    // Ensure storyData is loaded
    if (!storyData || !storyData.days) {
      alert('Story data is not loaded yet.');
      return;
    }

    // Current day details
    const currentDay = storyData.days[currentDayIndex];
    if (!currentDay) {
      alert('Current day data not found.');
      return;
    }

    const dayNum = currentDay.day;
    const currentAudioUrls = storyData.metadata?.audioUrls?.[dayNum] || {};

    const steps = stepsOrder.filter((action) => action in currentAudioUrls);
    if (steps.length === 0) {
      alert('No audio available for this day.');
      return;
    }

    setIsPlaying(true);
    let playIndex = 0;

    const playNext = () => {
      if (playIndex >= steps.length) {
        setIsPlaying(false);
        setProgress(0); // Reset progress
        setCurrentTime(0);
        setDuration(0);
        return;
      }

      const step = steps[playIndex];
      const url = currentAudioUrls[step];

      if (Array.isArray(url)) {
        // Handle Shadow Reading
        const sentences = currentDay.content.match(/[^\.!\?]+[\.!\?]+/g) || [];
        playShadowReading(url, sentences);
        playIndex += 1;
      } else {
        // Handle Regular Audio Steps
        const audio = new Audio(url);
        audioRef.current = audio;

        // Reset progress
        setProgress(0);
        setCurrentTime(0);
        setDuration(0);

        // Add event listeners
        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', () => {
          setDuration(audio.duration);
        });

        audio.play().catch((err) => {
          console.error('Error playing audio:', err);
          setError('Error playing audio.');
          setIsPlaying(false);
          setProgress(0); // Reset progress
          setCurrentTime(0);
          setDuration(0);
        });

        audio.onended = () => {
          audio.removeEventListener('timeupdate', handleTimeUpdate);
          audio.removeEventListener('loadedmetadata', () => {});
          playIndex += 1;
          playNext();
        };
      }
    };

    playNext();
  };

  // Handler to toggle play/pause
  const handlePlayPause = () => {
    if (!isPlaying && !isShadowPlaying) {
      // If nothing is playing, start playing
      handlePlayAll();
    } else if (isPaused) {
      // If paused, resume playing
      if (audioRef.current) {
        audioRef.current.play().catch((err) => {
          console.error('Error resuming audio:', err);
          setError('Error resuming audio.');
          setIsPaused(false); // Reset pause state on error
        });
      }
      if (isShadowPlaying) {
        // Resume shadow reading from the last index
        const currentDay = storyData.days[currentDayIndex];
        const dayNum = currentDay.day;
        const currentAudioUrls = storyData.metadata?.audioUrls?.[dayNum] || {};
        const shadowUrls = currentAudioUrls['SHADOW'];
        const sentences = currentDay.content.match(/[^\.!\?]+[\.!\?]+/g) || [];
        playShadowReading(shadowUrls, sentences, shadowPlayIndex);
      }
      setIsPaused(false);
    } else {
      // If playing, pause the audio
      if (audioRef.current) {
        audioRef.current.pause();
      }
      if (isShadowPlaying && shadowTimeoutRef.current) {
        clearTimeout(shadowTimeoutRef.current);
        shadowTimeoutRef.current = null;
      }
      setIsPaused(true);
    }
  };

  // Handler to change day via dropdown
  const handleChangeDay = (e) => {
    const newIndex = e.target.value;
    setCurrentDayIndex(newIndex);
    // Stop playback when changing day
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
    if (shadowTimeoutRef.current) {
      clearTimeout(shadowTimeoutRef.current);
      shadowTimeoutRef.current = null;
    }
    setIsPlaying(false);
    setIsShadowPlaying(false);
    setShadowPlayIndex(0);
    setIsPaused(false);
    setProgress(0);
    setCurrentTime(0);
    setDuration(0);
  };

  // Handler to go to the next day
  const handleNextDay = () => {
    if (currentDayIndex < storyData.days.length - 1) {
      const newIndex = currentDayIndex + 1;
      setCurrentDayIndex(newIndex);
      // Stop playback when changing day
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      if (shadowTimeoutRef.current) {
        clearTimeout(shadowTimeoutRef.current);
        shadowTimeoutRef.current = null;
      }
      setIsPlaying(false);
      setIsShadowPlaying(false);
      setShadowPlayIndex(0);
      setIsPaused(false);
      setProgress(0);
      setCurrentTime(0);
      setDuration(0);
    }
  };

  // Handler to go to the previous day
  const handlePreviousDay = () => {
    if (currentDayIndex > 0) {
      const newIndex = currentDayIndex - 1;
      setCurrentDayIndex(newIndex);
      // Stop playback when changing day
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      if (shadowTimeoutRef.current) {
        clearTimeout(shadowTimeoutRef.current);
        shadowTimeoutRef.current = null;
      }
      setIsPlaying(false);
      setIsShadowPlaying(false);
      setShadowPlayIndex(0);
      setIsPaused(false);
      setProgress(0);
      setCurrentTime(0);
      setDuration(0);
    }
  };

  // Conditional Rendering
  if (loading) {
    return (
      <Container maxWidth="md">
        <Box mt={4} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Box mt={4} p={2} bgcolor="#ffebee" borderRadius={4}>
          <Typography color="error" variant="body1">
            {error}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            style={{ marginTop: '16px' }}
          >
            Go Back
          </Button>
        </Box>
      </Container>
    );
  }

  // Current story details
  const currentStory = storyData.days[currentDayIndex];
  const dayNum = currentStory.day;
  const currentAudioUrls = storyData.metadata?.audioUrls?.[dayNum] || {};
  const currentTranslations = storyData.metadata?.translations?.[dayNum] || {};

  return (
    <Container maxWidth="lg" sx={{ mt: 13, mb: 8 }}>
      {/* **New Playback Controls at the Top** */}
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        position="sticky"
        top={0}
        zIndex={10}
        bgcolor="background.paper"
        p={2}
        boxShadow={3}
        mb={4} // Added bottom margin for space
      >
        {/* Play/Pause Button */}
        <IconButton
          onClick={handlePlayPause}
          disabled={false} // Always enabled
          size="large"
          aria-label={
            isPaused
              ? 'Resume Playback'
              : isPlaying
              ? 'Pause Playback'
              : 'Start Playback'
          }
          sx={{
            backgroundColor: 'primary.main',
            color: '#fff',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
            width: 60,
            height: 60,
            borderRadius: '50%', // Circular
            boxShadow: 3, // Shadow for prominence
          }}
        >
          {isPaused || (!isPlaying && !isShadowPlaying) ? (
            <PlayArrowIcon sx={{ fontSize: 30 }} />
          ) : (
            <PauseIcon sx={{ fontSize: 30 }} />
          )}
        </IconButton>

        {/* Playback Progress Slider */}
        <Box flex={1}>
          <Slider
            value={
              isSeeking
                ? currentTime
                : audioRef.current
                ? audioRef.current.currentTime
                : 0
            }
            min={0}
            max={duration}
            step={0.1}
            onChange={(e, value) => {
              setCurrentTime(value);
              setProgress((value / duration) * 100);
            }}
            onChangeCommitted={(e, value) => {
              if (audioRef.current) {
                audioRef.current.currentTime = value;
              }
              setIsSeeking(false);
            }}
            onMouseDown={() => setIsSeeking(true)}
            onMouseUp={() => setIsSeeking(false)}
            aria-labelledby="audio-slider"
            sx={{
              color: 'primary.main',
            }}
          />
          <Box display="flex" justifyContent="space-between" mt={0.5}>
            <Typography variant="caption">
              {formatTime(currentTime)}
            </Typography>
            <Typography variant="caption">
              {formatTime(duration)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* **End of New Playback Controls** */}

      {/* Optional: Add Divider for visual separation */}
      {/* <Divider sx={{ mb: 4 }} /> */}

      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={3} mt={2}>
        {/* LEFT COLUMN: Image and Story Display */}
        <Box flex={1}>
          {/* Image Section */}
          {storyData.metadata?.imageUrl && (
            <Box mb={2} textAlign="center">
              <img
                src={storyData.metadata.imageUrl}
                alt="Generated Illustration"
                style={{
                  width: '100%',
                  maxHeight: '400px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>
          )}

          {/* Story Title */}
          <Typography variant="storyTitle" gutterBottom>
            {storyData.title} (Day {dayNum})
          </Typography>

          {/* Story Content */}
          <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6">Day {dayNum}:</Typography>
            <Typography variant="storyContent">
              {currentStory.content}
            </Typography>
          </Paper>

          {/* Display Translations if any */}
          {Object.keys(currentTranslations).length > 0 && (
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Translations:
              </Typography>
              {Object.entries(currentTranslations).map(([language, text]) => (
                <Paper key={language} elevation={1} sx={{ p: 2, mb: 1 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {language}:
                  </Typography>
                  <Typography style={{ whiteSpace: 'pre-line' }}>
                    {text}
                  </Typography>
                </Paper>
              ))}
            </Box>
          )}

          {/* Error Message */}
          {error && (
            <Box mt={2} p={2} bgcolor="#ffebee" borderRadius={4}>
              <Typography color="error">{error}</Typography>
            </Box>
          )}
        </Box>

        {/* RIGHT COLUMN: Day Navigation and Steps */}
        <Box
          sx={{
            position: 'sticky',
            top: 80, // Adjusted to account for the sticky playback controls
            alignSelf: 'start',
            width: { xs: '100%', md: '350px' },
            height: 'fit-content',
          }}
        >
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Voice & Playback Controls (Day {dayNum})
            </Typography>

            {/* Day Selection and Navigation */}
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              {/* Previous Day Button */}
              <IconButton
                color="primary"
                onClick={handlePreviousDay}
                disabled={currentDayIndex === 0}
                aria-label="previous day"
              >
                <ArrowBackIcon />
              </IconButton>

              {/* Day Selection Dropdown */}
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Day</InputLabel>
                <Select
                  label="Day"
                  value={currentDayIndex}
                  onChange={handleChangeDay}
                >
                  {storyData.days.map((s, idx) => (
                    <MenuItem key={s.day} value={idx}>
                      {s.day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Next Day Button */}
              <IconButton
                color="primary"
                onClick={handleNextDay}
                disabled={currentDayIndex === storyData.days.length - 1}
                aria-label="next day"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>

            {/* Steps List */}
            <Typography variant="subtitle1" mt={2}>
              Steps for Day {dayNum}:
            </Typography>
            {Object.keys(currentAudioUrls).length === 0 ? (
              <Typography variant="body2">No steps available.</Typography>
            ) : (
              <List>
                {stepsOrder.length === 0 ? (
                  <Typography variant="body2">No steps available.</Typography>
                ) : (
                  <List>
                    {stepsOrder.map((stepAction) => {
                      const url = currentAudioUrls[stepAction];
                      // Possibly check if it's array or single URL
                      // Display the correct label, color, etc.
                      
                      return (
                        <ListItem
                          key={stepAction}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderLeft: `5px solid ${stepColorMapping[stepAction] || '#000'}`,
                            backgroundColor: '#f5f5f5',
                            borderRadius: '4px',
                            mb: 1,
                            p: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ mr: 1, flexGrow: 1, color: '#333' }}
                          >
                            {stepAction.replace('_', ' ')}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </List>
            )}
          </Paper>
        </Box>
      </Box>
    </Container>
  );
}

export default GeneratedStoryPage;

// HistoryPage.js
import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext'; // Adjust the import path as necessary
import { db, storage } from './firebase'; // Ensure storage is imported
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  deleteDoc,
  orderBy,
  getCountFromServer, // Firestore count aggregation
} from 'firebase/firestore';
import {
  Typography,
  Container,
  Box,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  IconButton,
  Grid,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete'; // Import Delete Icon
import { useNavigate } from 'react-router-dom';
import { ref, listAll, deleteObject } from 'firebase/storage'; // Import Storage functions

// Snackbar Alert Component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HistoryPage() {
  const { currentUser } = useAuth();
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // State for Confirmation Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [storyToDelete, setStoryToDelete] = useState(null);

  // State for Snackbar Notifications
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchUserStories = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userStoryCollection = collection(
          db,
          'users',
          currentUser.uid,
          'generatedStories'
        );

        // Fetch stories ordered by creation time descending
        const userStoriesSnapshot = await getDocs(
          query(userStoryCollection, orderBy('createdAt', 'desc'))
        );

        const fetchedStories = [];

        // Fetch number of days for each story concurrently
        const storiesPromises = userStoriesSnapshot.docs.map(async (docSnap) => {
          const data = docSnap.data();
          const storyId = docSnap.id;
          const title = data.title || 'Untitled Story';
          const createdAt = data.createdAt ? data.createdAt.toDate() : null;

          // Fetch the total number of days using Firestore's count aggregation
          let numberOfDays = 0;
          try {
            const daysCollection = collection(
              db,
              'users',
              currentUser.uid,
              'generatedStories',
              storyId,
              'days'
            );

            const countSnapshot = await getCountFromServer(daysCollection);
            numberOfDays = countSnapshot.data().count;
          } catch (err) {
            console.error(`Error fetching days count for story ${storyId}:`, err);
          }

          // Fetch the first day's content as a snippet
          let snippet = '';
          try {
            const daysQuery = query(
              collection(
                db,
                'users',
                currentUser.uid,
                'generatedStories',
                storyId,
                'days'
              ),
              orderBy('day', 'asc'),
              where('day', '==', 1)
            );

            const daysSnapshot = await getDocs(daysQuery);

            if (!daysSnapshot.empty) {
              const firstDayData = daysSnapshot.docs[0].data();
              snippet = firstDayData.content
                ? firstDayData.content.substring(0, 200) + (firstDayData.content.length > 200 ? '...' : '')
                : '';
            }
          } catch (err) {
            console.error(`Error fetching days for story ${storyId}:`, err);
          }

          return {
            storyId,
            title,
            createdAt,
            snippet,
            numberOfDays,
          };
        });

        const storiesWithDays = await Promise.all(storiesPromises);

        setStories(storiesWithDays);
      } catch (err) {
        console.error('Error fetching stories:', err);
        setError('Error fetching your stories. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserStories();
  }, [currentUser]);

  /**
   * Helper: format timestamp for display
   */
  const formatTimestamp = (ts) => {
    if (!ts) return 'Unknown date';
    return ts.toLocaleString();
  };

  /**
   * Handle click on delete button to open confirmation dialog
   */
  const handleDeleteClick = (storyId) => {
    setStoryToDelete(storyId);
    setOpenDialog(true);
  };

  /**
   * Confirm deletion of story
   */
  const confirmDeleteStory = async () => {
    if (storyToDelete) {
      await handleDeleteStory(storyToDelete);
    }
    setOpenDialog(false);
    setStoryToDelete(null);
  };

  /**
   * Cancel deletion
   */
  const cancelDelete = () => {
    setOpenDialog(false);
    setStoryToDelete(null);
  };

  /**
   * Delete story by storyId, including associated audio files in Firebase Storage
   */
  const handleDeleteStory = async (storyIdToDelete) => {
    if (!currentUser) return;

    try {
      // Reference to the story document
      const storyDocRef = doc(db, 'users', currentUser.uid, 'generatedStories', storyIdToDelete);

      // Delete the story document
      await deleteDoc(storyDocRef);

      // Delete associated audio files in Firebase Storage
      const audioFilesPath = `users/${currentUser.uid}/generatedStories/${storyIdToDelete}/audioFiles`;
      const audioFilesRef = ref(storage, audioFilesPath);

      // List all audio files under the specified path
      const audioFilesList = await listAll(audioFilesRef);

      // Check if there are audio files to delete
      if (audioFilesList.items.length > 0) {
        // Delete each audio file
        const deletePromises = audioFilesList.items.map((itemRef) => deleteObject(itemRef));
        await Promise.all(deletePromises);
      }

      // Remove the story from local state
      setStories((prev) => prev.filter((story) => story.storyId !== storyIdToDelete));

      // Show success Snackbar
      setSnackbar({ open: true, message: 'Story deleted successfully.', severity: 'success' });
    } catch (err) {
      console.error(`Error deleting story ${storyIdToDelete}:`, err);
      setError('Failed to delete the story and its audio files. Please try again.');
      // Show error Snackbar
      setSnackbar({ open: true, message: 'Failed to delete the story.', severity: 'error' });
    }
  };

  /**
   * Handle Snackbar Close
   */
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box mt={4} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" style={{ marginTop: '50px' }}>
      <Box mt={4}>
        {/* Restore the previous font by using the existing className "Subtitle" */}
        <div className="Subtitle" style={{ textAlign: 'center', marginBottom: '20px' }}>
          Your Story History
        </div>

        {error && (
          <Box mt={2} p={2} bgcolor="#ffebee" borderRadius={4}>
            <Typography color="error" variant="body1">
              {error}
            </Typography>
          </Box>
        )}

        {stories.length > 0 ? (
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            {stories.map((story) => {
              const { storyId, title, createdAt, snippet, numberOfDays } = story;

              return (
                <Grid item xs={12} key={storyId}>
                  <Card
                    variant="outlined"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      boxShadow: 3,
                      '&:hover': {
                        boxShadow: 6,
                      },
                    }}
                  >
                    <CardActionArea
                      onClick={() =>
                        navigate('/generated-story', {
                          state: { storyId, title },
                        })
                      }
                      sx={{ flexGrow: 1 }}
                    >
                      <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" color="textSecondary">
                            {createdAt ? `Generated on: ${formatTimestamp(createdAt)}` : 'Generated on: Unknown date'}
                          </Typography>
                          {/* Move the number of days to the left of the "Days" text */}
                          <Tooltip title={`${numberOfDays} day${numberOfDays !== 1 ? 's' : ''}`}>
                            <Box display="flex" alignItems="center">
                              <Box
                                sx={{
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  backgroundColor: 'primary.main',
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: '0.8rem',
                                  marginRight: '8px',
                                }}
                              >
                                {numberOfDays}
                              </Box>
                              <Typography variant="body2">Days</Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                        <Typography variant="h6" sx={{ marginTop: 1 }}>
                          {title}
                        </Typography>
                        {snippet && (
                          <Typography
                            variant="body2"
                            sx={{
                              marginTop: 1,
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {snippet}
                          </Typography>
                        )}
                      </CardContent>
                    </CardActionArea>

                    {/* Delete Section: IconButton aligned to the right */}
                    <CardActions
                      sx={{
                        justifyContent: 'flex-end',
                        paddingRight: '16px',
                        paddingBottom: '8px',
                      }}
                    >
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the CardActionArea
                          handleDeleteClick(storyId);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1" align="center" style={{ marginTop: '40px' }}>
            No stories generated yet.
          </Typography>
        )}

        {/* Confirmation Dialog */}
        <Dialog
          open={openDialog}
          onClose={cancelDelete}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              Are you sure you want to delete this story and all its associated audio files? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDeleteStory} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for Notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
}

export default HistoryPage;

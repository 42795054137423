// src/App.js

import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import getTheme from './theme'; // Ensure getTheme is the default export
import themeOptions from './themeOptions';
import StartPage from './StartPage';
import ChatPage from './ChatPage';
import StoryPage from './StoryPage';
import VoiceSelectionPage from './VoiceSelectionPage';
import GeneratedStoryPage from './GeneratedStoryPage';
import Login from './Login';
import Register from './Register';
import NavBar from './NavBar';
import PrivateRoute from './PrivateRoute';
import HistoryPage from './HistoryPage'; // Import HistoryPage

function App() {
  // 1) Pick one random theme index
  const randomIndex = Math.floor(Math.random() * themeOptions.length);
  const chosenThemeOption = themeOptions[randomIndex];

  // 2) Memoize the MUI theme
  const theme = useMemo(() => {
    return getTheme(chosenThemeOption.palette);
  }, [chosenThemeOption]);

  // 3) Provide a way for child components to read the chosen backgrounds
  // Option A: store them in a React Context
  // Option B: pass as props
  // Option C: attach them to the theme object itself (like theme.startPageBackground)
  // We'll do Option C for simplicity

  // Attach StartPage and Story wallpapers onto the theme object:
  theme.startPageBackground = chosenThemeOption.startPageBackground;
  theme.wallpaper = chosenThemeOption.wallpaper;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <NavBar/>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <ChatPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/story"
            element={
              <PrivateRoute>
                <StoryPage />
              </PrivateRoute>
            }
          />
          <Route path="/voice-selection" element={<VoiceSelectionPage />} />
          <Route
            path="/generated-story"
            element={
              <PrivateRoute>
                <GeneratedStoryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/history"
            element={
              <PrivateRoute>
                <HistoryPage />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

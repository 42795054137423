import React, { useState } from 'react';
import { Button, Container, Typography, Box, TextField, Alert, CircularProgress } from '@mui/material';
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  React.useEffect(() => {
    if (currentUser) {
      // If user is already logged in, redirect them
      navigate('/chat');
    }
  }, [currentUser, navigate]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGoogleSignIn = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('User signed in with Google:', result.user);
        navigate('/chat');
      })
      .catch((error) => {
        console.error('Error during Google sign-in:', error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('User signed in with email:', userCredential.user);
      navigate('/chat');
    } catch (err) {
      console.error('Email login error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '100px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Sign In to iBot Story Generator
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Box component="form" onSubmit={handleEmailLogin} sx={{ mt: 3 }}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          required
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Login with Email
        </Button>
      </Box>
      {loading && <CircularProgress sx={{ mt: 2 }} />}
      <Typography variant="body2" sx={{ mt: 2 }}>
        Don't have an account? <Link to="/register">Register here</Link>
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">OR</Typography>
        <Button variant="contained" color="secondary" onClick={handleGoogleSignIn} sx={{ mt: 2 }}>
          Sign in with Google
        </Button>
      </Box>
    </Container>
  );
};

export default Login;

// VoiceSelectionPage.js
import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  List,
  ListItem,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  LinearProgress,
  Paper,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import './App.css';
import { db, storage } from './firebase';
import { collection, addDoc, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from './AuthContext';

// Define backendUrl
const backendUrl =
  process.env.REACT_APP_BACKEND_URL ||
  'https://ibotstorybackend-f6e0c4f9h9bkbef8.eastus2-01.azurewebsites.net';

// Mapping from translation language to recommended voice
const TRANSLATION_VOICE_MAP = {
  Chinese: 'zh-CN-XiaoxiaoNeural',
  Spanish: 'es-ES-ElviraNeural',
  French: 'fr-FR-DeniseNeural',
  German: 'de-DE-KatjaNeural',
  Japanese: 'ja-JP-NanamiNeural',
};

// Additional multi-language voices for user selection
const ALL_AVAILABLE_VOICES = [
  { label: 'Jenny (US)', value: 'en-US-JennyNeural' },
  { label: 'Guy (US)', value: 'en-US-GuyNeural' },
  { label: 'Libby (UK)', value: 'en-GB-LibbyNeural' },
  { label: 'Ryan (UK)', value: 'en-GB-RyanNeural' },
  { label: 'Natasha (AU)', value: 'en-AU-NatashaNeural' },
  { label: 'Neerja (IN)', value: 'en-IN-NeerjaNeural' },
  { label: 'Xiaoxiao (Chinese)', value: 'zh-CN-XiaoxiaoNeural' },
  { label: 'Yunxi (Chinese)', value: 'zh-CN-YunxiNeural' },
  { label: 'Elvira (Spanish)', value: 'es-ES-ElviraNeural' },
  { label: 'Alvaro (Spanish)', value: 'es-ES-AlvaroNeural' },
  { label: 'Denise (French)', value: 'fr-FR-DeniseNeural' },
  { label: 'Henri (French)', value: 'fr-FR-HenriNeural' },
  { label: 'Katja (German)', value: 'de-DE-KatjaNeural' },
  { label: 'Nanami (Japanese)', value: 'ja-JP-NanamiNeural' },
];

// Available translation languages
const TRANSLATION_LANGUAGES = Object.keys(TRANSLATION_VOICE_MAP);

function VoiceSelectionPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const wallpaper = theme.wallpaper;

  // Destructure story configurations and generateImage from location.state
  const {
    storyLanguage = '',
    numDays = 1,
    languageDifficulty = '',
    storyLength = '',
    title = '',
    storyType = '',
    time = '',
    place = '',
    characterName = '',
    protagonist = '',
    mainStoryline = '',
    generateImage = false, // Receive the generateImage flag
  } = location.state || {};

  // State variables
  const [selectedVoice, setSelectedVoice] = useState('en-US-JennyNeural');
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] = useState('Chinese');
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [error, setError] = useState('');

  // Audio URLs structured as { day: { step: audioUrl } }
  const [audioUrls, setAudioUrls] = useState({});

  // Image URL state
  const [imageUrl, setImageUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  // Progress Bar State
  const [progress, setProgress] = useState(0);
  const totalSteps = useRef(0); // Total number of steps for progress calculation

  // Updated Step Color Mapping with more colorful hues
  const stepColorMapping = {
    READ_ALOUD: theme.palette.primary.main,
    SHADOW: theme.palette.success.main,
    TRANSLATE_VOICE: theme.palette.secondary.main,
  };

  const { currentUser } = useAuth(); // Access the current user

  // Handler to add steps
  const handleAddStep = (action) => {
    let label = '';
    if (action === 'READ_ALOUD') {
      label = 'Read Aloud';
    } else if (action === 'SHADOW') {
      label = 'Shadow Reading';
    } else if (action === 'TRANSLATE_VOICE') {
      label = `Voice Translation (${selectedTranslationLanguage})`;
    }

    // Prevent adding duplicate steps for the same action and language
    if (
      steps.some(
        (step) => step.action === action && (action !== 'TRANSLATE_VOICE' || step.language === selectedTranslationLanguage)
      )
    ) {
      alert('This step is already added.');
      return;
    }

    setSteps((prev) => [
      ...prev,
      { id: Date.now(), action, label, language: action === 'TRANSLATE_VOICE' ? selectedTranslationLanguage : null },
    ]);

    console.log(`Added step: ${label}`);
  };

  // Handler to delete steps
  const handleDeleteStep = (id) => {
    const stepToDelete = steps.find((step) => step.id === id);
    if (stepToDelete) {
      console.log(`Deleted step: ${stepToDelete.label}`);
    }
    setSteps((prev) => prev.filter((step) => step.id !== id));
  };

  // Story length parser
  const getLengthInstruction = (input) => {
    if (!input || !input.trim()) {
      return 'Keep it around 300 words.';
    }
    if (input.includes('-')) {
      const [minRaw, maxRaw] = input.split('-').map((str) => str.trim());
      const minVal = parseInt(minRaw, 10);
      const maxVal = parseInt(maxRaw, 10);
      if (!isNaN(minVal) && !isNaN(maxVal)) {
        return `Try to keep it between ${minVal} and ${maxVal} words.`;
      } else {
        return `Try to keep it around ${input} words.`;
      }
    }
    return `Try to keep it around ${input.trim()} words.`;
  };

  // Function to build the story prompt
  const buildStoryPrompt = (day, previousStory) => {
    let prompt = '';

    if (day === 1) {
      prompt = 'Write a story';

      // Conditionally include configurations
      if (storyLanguage && storyLanguage.trim()) {
        prompt += ` in ${storyLanguage.toLowerCase()}`;
      }
      if (languageDifficulty && languageDifficulty.trim()) {
        prompt += `, making it ${languageDifficulty.toLowerCase()} difficulty`;
      }

      if (title && title.trim()) {
        prompt += ` titled "${title}"`;
      }
      if (storyType && storyType.trim()) {
        prompt += ` in the ${storyType} genre`;
      }

      if (characterName && characterName.trim()) {
        prompt += ` featuring a character named ${characterName}`;
      }
      if (protagonist && protagonist.trim()) {
        prompt += ` who is ${protagonist}`;
      }

      if ((time && time.trim()) || (place && place.trim())) {
        prompt += ' set';
        if (time && time.trim()) {
          prompt += ` in ${time}`;
        }
        if (place && place.trim()) {
          prompt += time && time.trim() ? ` at ${place}` : ` in ${place}`;
        }
      }

      if (mainStoryline && mainStoryline.trim()) {
        prompt += ` with the main storyline focusing on ${mainStoryline}`;
      }

      prompt += '. ' + getLengthInstruction(storyLength);
    } else {
      // Day 2 or later
      if (previousStory.length > 1500) {
        // Summarize to keep prompt shorter
        const summaryPrompt = `Summarize the following story in 200 words:\n\n${previousStory}`;
        // Perform the summarization synchronously or handle it appropriately
        // For this example, we'll assume it's already summarized
        // const summaryRes = await axios.post(`${backendUrl}/api/chat`, { prompt: summaryPrompt });
        // previousStory = summaryRes.data.choices[0].message.content.trim();
        // For simplicity, we'll skip summarization
      }

      prompt += `Continue the following story into Day ${day}:\n\n${previousStory}\n\n`;

      // Keep language / difficulty consistent
      if (
        (languageDifficulty && languageDifficulty.trim()) ||
        (storyLanguage && storyLanguage.trim())
      ) {
        prompt += 'Ensure it remains';
        if (languageDifficulty && languageDifficulty.trim()) {
          prompt += ` ${languageDifficulty.toLowerCase()} difficulty`;
        }
        if (languageDifficulty && languageDifficulty.trim() && storyLanguage && storyLanguage.trim()) {
          prompt += ` and in ${storyLanguage.toLowerCase()}`;
        } else if (storyLanguage && storyLanguage.trim()) {
          prompt += ` in ${storyLanguage.toLowerCase()}`;
        }
        prompt += '. ';
      }

      // Story length again
      prompt += getLengthInstruction(storyLength);
    }

    return prompt;
  };

  // Main generate handler
  const handleGenerate = async () => {
    if (steps.length === 0) {
      alert('Please add at least one step before generating.');
      return;
    }

    if (!currentUser) {
      alert('You must be logged in to generate and store stories.');
      return;
    }

    setLoading(true);
    setError('');
    setCurrentAction('Initializing Generation');
    setProgress(0);
    totalSteps.current = 0; // Reset total steps
    let completedSteps = 0;

    try {
      const totalDays = numDays ? parseInt(numDays, 10) : 1;
      const generatedStories = [];

      let previousStory = '';

      // Initialize a local object to store translations
      const generatedTranslations = {};

      // Calculate total steps for progress
      // For each day:
      //   1. Story Generation
      //   For each step:
      //     - Audio Generation (Read Aloud, Shadow, Translate Voice)
      // Finally, if generateImage is true, add image generation step
      totalSteps.current = totalDays * (1 + steps.length) + (generateImage ? 1 : 0);

      // Step 0: Initialize Firestore document for the story
      const storyDocRef = await addDoc(collection(db, 'users', currentUser.uid, 'generatedStories'), {
        createdAt: serverTimestamp(),
        title: title || 'Untitled Story',
        // Add any other metadata you want to store about the story
      });
      const storyId = storyDocRef.id;

      // Step 1: Generate Story for each day
      for (let day = 1; day <= totalDays; day++) {
        setCurrentAction(`Generating Story (Day ${day})`);
        setProgress(((++completedSteps) / totalSteps.current) * 100);

        const prompt = buildStoryPrompt(day, previousStory);

        console.log(`Generating story for Day ${day} with prompt: "${prompt}"`);

        // Call the backend
        const response = await axios.post(`${backendUrl}/api/chat`, { prompt });
        const storyContent = response.data.choices[0].message.content.trim();
        generatedStories.push({ day, content: storyContent });
        previousStory = storyContent;

        console.log(`Generated story for Day ${day}: "${storyContent}"`);

        // Store the story content in Firestore
        await addDoc(collection(db, 'users', currentUser.uid, 'generatedStories', storyId, 'days'), {
          day,
          content: storyContent,
          timestamp: serverTimestamp(),
        });
      }

      // Step 2: Generate Audio for each step in each day
      const newAudioUrls = {};

      for (let dayObj of generatedStories) {
        const { day, content: story } = dayObj;

        newAudioUrls[day] = {};

        for (let step of steps) {
          if (step.action === 'READ_ALOUD') {
            setCurrentAction(`Generating Read Aloud Audio (Day ${day})`);
            setProgress(((++completedSteps) / totalSteps.current) * 100);

            try {
              // Generate audio using backend
              const audioBlob = await axios.post(
                `${backendUrl}/api/tts`,
                { text: `Day ${day}: ${story}`, voice: selectedVoice },
                { responseType: 'blob' }
              ).then(res => res.data);

              // Create a File object from the blob
              const file = new File([audioBlob], 'READ_ALOUD.mp3', { type: 'audio/mpeg' });

              // Define the storage path
              const storagePath = `users/${currentUser.uid}/stories/${storyId}/day${day}/READ_ALOUD.mp3`;
              const audioRefStorage = ref(storage, storagePath);

              // Upload the file using Firebase SDK
              await uploadBytes(audioRefStorage, file);
              const audioUrl = await getDownloadURL(audioRefStorage);
              newAudioUrls[day]['READ_ALOUD'] = audioUrl;

              console.log(`Read Aloud Audio for Day ${day}:`, audioUrl);

              // Store the audio URL in Firestore
              await addDoc(collection(db, 'users', currentUser.uid, 'generatedStories', storyId, 'days', `${day}`, 'steps'), {
                step: 'READ_ALOUD',
                audioUrl,
              });
            } catch (err) {
              console.error(`Error generating Read Aloud audio for Day ${day}:`, err);
              setError(`Error generating Read Aloud audio for Day ${day}.`);
              // Continue to next step
            }
          } else if (step.action === 'SHADOW') {
            setCurrentAction(`Generating Shadow Reading Audio (Day ${day})`);
            setProgress(((++completedSteps) / totalSteps.current) * 100);

            // For shadow reading, split into sentences and generate audio with pauses
            const sentences = story.match(/[^\.!\?]+[\.!\?]+/g) || [];
            const shadowAudioUrls = [];

            try {
              for (let i = 0; i < sentences.length; i++) {
                const sentence = sentences[i];
                const audioBlob = await axios.post(
                  `${backendUrl}/api/tts`,
                  { text: sentence, voice: selectedVoice },
                  { responseType: 'blob' }
                ).then(res => res.data);

                // Create a File object from the blob
                const file = new File([audioBlob], `sentence${i + 1}.mp3`, { type: 'audio/mpeg' });

                // Define the storage path
                const shadowAudioRefStorage = ref(
                  storage,
                  `users/${currentUser.uid}/stories/${storyId}/day${day}/SHADOW/sentence${i + 1}.mp3`
                );

                // Upload the file using Firebase SDK
                await uploadBytes(shadowAudioRefStorage, file);
                const audioUrl = await getDownloadURL(shadowAudioRefStorage);
                shadowAudioUrls.push(audioUrl);

                console.log(`Shadow Reading Audio Segment ${i + 1} for Day ${day}:`, audioUrl);

                // Store each shadow audio URL in Firestore
                await addDoc(collection(db, 'users', currentUser.uid, 'generatedStories', storyId, 'days', `${day}`, 'steps'), {
                  step: 'SHADOW',
                  sentence: sentence.trim(),
                  audioUrl,
                });
              }

              newAudioUrls[day]['SHADOW'] = shadowAudioUrls;
              console.log(`Shadow Reading Audio for Day ${day}:`, shadowAudioUrls);
            } catch (err) {
              console.error(`Error generating Shadow Reading audio for Day ${day}:`, err);
              setError(`Error generating Shadow Reading audio for Day ${day}.`);
              // Continue to next step
            }
          } else if (step.action === 'TRANSLATE_VOICE') {
            setCurrentAction(`Generating Voice for Translation (${step.language}) (Day ${day})`);
            setProgress(((++completedSteps) / totalSteps.current) * 100);

            try {
              // Step 1: Translate the story
              const translationPrompt = `Translate the following story into ${step.language}:\n\n${story}`;
              const translationResponse = await axios.post(`${backendUrl}/api/chat`, {
                prompt: translationPrompt,
              });
              const translatedText = translationResponse.data.choices[0].message.content.trim();

              console.log(`Translated Story for Day ${day} (${step.language}): "${translatedText}"`);

              // Step 2: Generate voice for translated text using backend
              const translationVoice =
                TRANSLATION_VOICE_MAP[step.language] || 'zh-CN-XiaoxiaoNeural';

              const audioBlob = await axios.post(
                `${backendUrl}/api/tts`,
                { text: `Day ${day}: ${translatedText}`, voice: translationVoice },
                { responseType: 'blob' }
              ).then(res => res.data);

              // Create a File object from the blob
              const file = new File([audioBlob], `TRANSLATE_VOICE_${step.language}.mp3`, { type: 'audio/mpeg' });

              // Define the storage path
              const translateVoiceRefStorage = ref(
                storage,
                `users/${currentUser.uid}/stories/${storyId}/day${day}/TRANSLATE_VOICE_${step.language}.mp3`
              );

              // Upload the file using Firebase SDK
              await uploadBytes(translateVoiceRefStorage, file);
              const audioUrl = await getDownloadURL(translateVoiceRefStorage);
              newAudioUrls[day]['TRANSLATE_VOICE'] = audioUrl;

              console.log(
                `Translation Voice Audio for Day ${day} (${step.language}):`,
                audioUrl
              );

              // Store the translated text and audio URL in Firestore
              if (!generatedTranslations[day]) {
                generatedTranslations[day] = {};
              }
              generatedTranslations[day][step.language] = translatedText;

              await addDoc(collection(db, 'users', currentUser.uid, 'generatedStories', storyId, 'days', `${day}`, 'steps'), {
                step: 'TRANSLATE_VOICE',
                language: step.language,
                translatedText,
                audioUrl,
              });
            } catch (err) {
              console.error(`Error generating Translation Voice audio for Day ${day}:`, err);
              setError(`Error generating Translation Voice audio for Day ${day} (${step.language}).`);
              // Continue to next step
            }
          }
        }
      }

      // Step 3: Generate Image if selected
      let generatedImageUrl = null;
      if (generateImage) {
        setCurrentAction('Generating Image');
        setImageLoading(true);
        setProgress(((++completedSteps) / totalSteps.current) * 100);

        try {
          const latestStory = generatedStories[generatedStories.length - 1].content;
          const imagePrompt = `Create an illustration based on the following story:\n\n${latestStory}`;
          const imageResponse = await axios.post(
            `${backendUrl}/api/generateImage`, // Ensure your backend has an endpoint for image generation
            { prompt: imagePrompt },
            { responseType: 'json' } // Change from 'blob' to 'json'
          );

          generatedImageUrl = imageResponse.data.imageUrl; // Directly use imageUrl from response
          console.log('Generated Image URL:', generatedImageUrl);

          // Optionally, upload the image to Firebase Storage
          // If the image is already hosted, you can skip this step
        } catch (err) {
          console.error('Error generating image:', err);
          setError('Error generating image.');
        } finally {
          setImageLoading(false);
          setProgress(((completedSteps) / totalSteps.current) * 100);
          setCurrentAction('');
        }
      }

      // Step 4: Log the final audioUrls and translations before navigation
      console.log('Final Audio URLs:', newAudioUrls);
      console.log('Final Translations:', generatedTranslations);
      console.log('Final Image URL:', generatedImageUrl);

      // Step 5: Store audioUrls and translations in Firestore as a single document
      await setDoc(doc(db, 'users', currentUser.uid, 'generatedStories', storyId, 'metadata', 'main'), {
        audioUrls: newAudioUrls,
        translations: generatedTranslations,
        imageUrl: generatedImageUrl,
        stepsOrder: steps.map((stepObj) => stepObj.action), // <--- Store step actions in order
      }, { merge: true }); // Use merge to avoid overwriting existing data if necessary

      // Step 6: Navigate to GeneratedStoryPage with the generated stories, audio URLs, translations, and image URL
      navigate('/generated-story', {
        state: { storyId, title },
      });
    } catch (err) {
      console.error('Unexpected error during generation:', err);
      setError('An unexpected error occurred during generation.');
    } finally {
      setLoading(false);
      setCurrentAction('');
      setProgress(100); // Ensure progress is complete
    }
  };

  return (
    <Box
      sx={{
        background: `url(${wallpaper}) no-repeat center center fixed`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        paddingTop: '80px',
        paddingBottom: '40px',
      }}
    >
      <Container maxWidth="md" sx={{ mt: 13, mb: 8 }}>
        {/* Voice and Translation Selection */}
        <Box mb={6}>
          <div className="Subtitle" style={{ textAlign: 'center', marginBottom: '20px' }}>
            Voice Selection
          </div>

          {/* Select Voice for Read Aloud */}
          <TextField
            fullWidth
            select
            label="Select Voice for Read Aloud"
            value={selectedVoice}
            onChange={(e) => setSelectedVoice(e.target.value)}
            variant="outlined"
            margin="normal"
            sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
          >
            {ALL_AVAILABLE_VOICES.map((voice) => (
              <MenuItem key={voice.value} value={voice.value}>
                {voice.label}
              </MenuItem>
            ))}
          </TextField>

          {/* Select Translation Language */}
          <FormControl fullWidth variant="outlined" margin="normal" sx={{ mt: 2 }}>
            <InputLabel>Select Translation Language</InputLabel>
            <Select
              label="Select Translation Language"
              value={selectedTranslationLanguage}
              onChange={(e) => setSelectedTranslationLanguage(e.target.value)}
              sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}
            >
              {TRANSLATION_LANGUAGES.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Three "Add" Buttons with different colors */}
          <Box mt={4} display="flex" flexDirection="column" gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddStep('READ_ALOUD')}
              sx={{
                height: '60px',
                borderRadius: '30px',
                fontSize: '20px',
                boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                textTransform: 'none',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.3)',
                },
              }}
            >
              Add Read Aloud
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleAddStep('TRANSLATE_VOICE')}
              sx={{
                height: '60px',
                borderRadius: '30px',
                fontSize: '20px',
                boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                textTransform: 'none',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.3)',
                },
              }}
            >
              Add Voice Translation
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleAddStep('SHADOW')}
              sx={{
                height: '60px',
                borderRadius: '30px',
                fontSize: '20px',
                boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                textTransform: 'none',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.3)',
                },
              }}
            >
              Add Shadow Reading
            </Button>
          </Box>
        </Box>

        {/* Steps List */}
        <Box mb={6}>
          <Typography variant="h5" gutterBottom>
            Steps to Play:
          </Typography>
          {steps.length === 0 ? (
            <Typography variant="body1" color="text.secondary">
              No steps added.
            </Typography>
          ) : (
            <List>
              {steps.map((step) => (
                <Paper
                  key={step.id}
                  elevation={2}
                  sx={{
                    mb: 1,
                    borderRadius: '8px',
                    backgroundColor: '#f5f5f5',
                    borderLeft: `5px solid ${stepColorMapping[step.action]}`, // Colored left border
                  }}
                >
                  <ListItem>
                    <ListItemText
                      primary={step.label}
                      secondary={step.action === 'TRANSLATE_VOICE' ? `Language: ${step.language}` : null}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteStep(step.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Paper>
              ))}
            </List>
          )}
        </Box>

        {/* Progress Bar */}
        <Box mb={4}>
          <Typography variant="body1" gutterBottom>
            {currentAction}
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
        </Box>

        {/* Generate Button */}
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerate}
            disabled={loading || steps.length === 0}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
            sx={{
              width: { xs: '100%', sm: '60%', md: '50%' },
              height: '60px',
              fontSize: '20px',
              borderRadius: '30px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
              },
              '&:disabled': {
                backgroundColor: '#80cbc4',
                color: '#ffffff',
                cursor: 'not-allowed',
              },
            }}
          >
            {loading ? currentAction : 'Generate'}
          </Button>
        </Box>

        {/* Image Generation Loading Indicator */}
        {imageLoading && (
          <Box mt={4} display="flex" alignItems="center" justifyContent="center" gap={2}>
            <Typography variant="body1">Generating Image...</Typography>
            <CircularProgress />
          </Box>
        )}

        {/* Error Message */}
        {error && (
          <Box mt={4} p={2} bgcolor="#ffebee" borderRadius={4}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default VoiceSelectionPage;

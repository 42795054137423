// themeOptions.js

import purpleWallpaper from './images/purple.png';
import pinkWallpaper from './images/pink.jpg';
import blueWallpaper from './images/blue.jpg';
import yellowWallpaper from './images/yellow.jpg';
import orangeWallpaper from './images/orange.jpg';
import greenWallpaper from './images/green.jpg';

// For your start page backgrounds:
import purpleStartBackground from './assets/purple_background.jpg';
import pinkStartBackground from './assets/pink_background.jpg';
import blueStartBackground from './assets/blue_background.jpg';
import yellowStartBackground from './assets/yellow_background.jpg';
import orangeStartBackground from './assets/orange_background.jpg';
import greenStartBackground from './assets/green_background.jpg';

const themeOptions = [
  // PURPLE THEME
  {
    palette: {
      primary: { main: '#621AE8' },    // Purple
      secondary: { main: '#ffa726' }, // Orange accent
      background: {
        default: '#f0f2f5',
        paper: '#ffffff',
      },
      text: {
        primary: '#000000',
      },
    },
    wallpaper: purpleWallpaper,
    startPageBackground: purpleStartBackground,
  },
  // BLUE THEME (Darker blue)
  {
    palette: {
      primary: { main: '#1565C0' },   // A bit darker blue than #1976D2
      secondary: { main: '#9c27b0' }, // Purple accent
      background: {
        default: '#e1f5fe',
        paper: '#ffffff',
      },
      text: {
        primary: '#212121',
      },
    },
    wallpaper: blueWallpaper,
    startPageBackground: blueStartBackground,
  },
  // PINK THEME (Less rose red)
  {
    palette: {
      primary: { main: '#EC407A' },    // Lighter pink, e.g. #EC407A
      secondary: { main: '#ff9800' }, // Orange accent
      background: {
        default: '#fff1f3',
        paper: '#ffffff',
      },
      text: {
        primary: '#212121',
      },
    },
    wallpaper: pinkWallpaper,
    startPageBackground: pinkStartBackground,
  },
  // YELLOW THEME
  {
    palette: {
      primary: { main: '#FDD835' }, // Yellow
      secondary: { main: '#f57c00' }, // Orange accent
      background: {
        default: '#fffde7', // Light yellow background
        paper: '#ffffff',
      },
      text: {
        primary: '#212121',
      },
    },
    wallpaper: yellowWallpaper,
    startPageBackground: yellowStartBackground,
  },
  // ORANGE THEME
  {
    palette: {
      primary: { main: '#FF9800' }, // Orange
      secondary: { main: '#4caf50' }, // Green accent
      background: {
        default: '#fff8e1', // Slightly off-white orange background
        paper: '#ffffff',
      },
      text: {
        primary: '#212121',
      },
    },
    wallpaper: orangeWallpaper,
    startPageBackground: orangeStartBackground,
  },
  // GREEN THEME
  {
    palette: {
      primary: { main: '#4CAF50' }, // Green
      secondary: { main: '#ff9800' }, // Orange accent
      success: { main: '#9c27b0' },
      background: {
        default: '#e8f5e9', // Light green background
        paper: '#ffffff',
      },
      text: {
        primary: '#212121',
      },
    },
    wallpaper: greenWallpaper,
    startPageBackground: greenStartBackground,
  },
];

export default themeOptions;

// src/theme.js
import { createTheme } from '@mui/material/styles';

// Import your custom fonts
import HandTitle from './fonts/waltographUI.ttf';
import TextFont from './fonts/VanillaExtractRegular.ttf';
import StoryFont from './fonts/Chocolate Covered Raindrops BOLD.ttf';
import FancyFont from './fonts/Alamak.ttf';

/**
 * getTheme(palette): create MUI theme from a passed-in palette
 * palette is an object: { primary, secondary, background, text, ... }
 */
const getTheme = (palette) =>
  createTheme({
    palette,
    typography: {
      fontFamily: 'TextFont, sans-serif',
      h6: {
        fontFamily: 'HandTitle, cursive',
      },
      subtitle1: {
        fontFamily: 'TextFont, sans-serif',
      },
      button: {
        fontFamily: 'TextFont, sans-serif',
        textTransform: 'none', // Prevent uppercase transformation
      },
      // Define a new typography variant for Story Title
      storyTitle: {
        fontFamily: 'FancyFont, cursive',
        fontSize: '2.5rem', // Adjust as needed
        lineHeight: 1.2,
      },
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: 'storyContent' },
            style: {
              fontFamily: 'cursive',
              fontSize: '1.4rem',
              lineHeight: 1.6,
            },
          },
          {
            props: { variant: 'storyTitle' },
            style: {
              fontFamily: 'FancyFont, cursive',
              fontSize: '4rem',
              lineHeight: 1.2,
            },
          },
        ],
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(4px)',
            backgroundColor: palette.primary
              ? `${palette.primary.main}CC` // 80% alpha
              : 'rgba(98, 26, 232, 0.8)',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '15px',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
            fontSize: '16px',
            padding: '8px 16px',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: palette.primary
              ? palette.primary.main
              : '#621AE8',
            transition: 'background-color 0.3s ease',
          },
        },
      },
    },
  });

export default getTheme;

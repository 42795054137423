// src/NavBar.js

import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useTheme,
  useMediaQuery,
  Fade, // Import Fade for fade-in effect
} from '@mui/material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Chat as ChatIcon,
  Book as BookIcon,
  History as HistoryIcon,
  Logout as LogoutIcon,
  Login as LoginIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles'; // Import styled
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import LogoutComponent from './Logout'; // Renamed to avoid conflict with MUI's LogoutIcon

// Define the NavButton component
const NavButton = styled(Button)(({ theme, active }) => ({
  position: 'relative',
  color: 'inherit',
  textTransform: 'none',
  transition: 'color 0.3s ease, transform 0.3s ease',
  fontSize: '16px',
  
  // Hover effects
  '&:hover': {
    color: theme.palette.secondary.main,
    transform: 'scale(1.05)', // Slightly enlarge the button on hover
  },
  
  // Underline effect
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -2, // Position the underline slightly below the button
    left: 0,
    width: active ? '100%' : '0%',
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    transition: 'width 0.3s ease',
  },
  
  // Active state styling
  ...(active && {
    color: theme.palette.secondary.main,
  }),
}));

function NavBar({ toggleTheme, mode }) {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const [show, setShow] = useState(false); // State for Fade-in effect

  useEffect(() => {
    // Trigger the fade-in effect after the component mounts
    setShow(true);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { text: 'Home', link: '/', icon: <HomeIcon /> },
    { text: 'Chat', link: '/chat', icon: <ChatIcon />, auth: true },
    { text: 'Story', link: '/story', icon: <BookIcon />, auth: true },
    { text: 'History', link: '/history', icon: <HistoryIcon />, auth: true },
  ];

  return (
    <>
      {/* Wrap AppBar with Fade for fade-in animation */}
      <Fade in={show} timeout={1000}>
        <AppBar position="static">
          <Toolbar>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              variant="h6"
              component={Link}
              to="/"
              sx={{
                flexGrow: 1,
                textDecoration: 'none',
                color: 'inherit',
                fontFamily: 'HandTitle, cursive',
              }}
            >
              iBot Story Generator
            </Typography>
            {!isMobile && (
              <Box sx={{ display: 'flex', gap: { xs: 2, sm: 3, md: 4 } }}> {/* Increased gap */}
                {menuItems.map((item) =>
                  item.auth ? (
                    currentUser && (
                      <NavButton
                        key={item.text}
                        component={Link}
                        to={item.link}
                        startIcon={item.icon}
                        active={location.pathname === item.link ? 1 : 0} // Pass active state as a prop
                      >
                        {item.text}
                      </NavButton>
                    )
                  ) : (
                    <NavButton
                      key={item.text}
                      component={Link}
                      to={item.link}
                      startIcon={item.icon}
                      active={location.pathname === item.link ? 1 : 0} // Pass active state as a prop
                    >
                      {item.text}
                    </NavButton>
                  )
                )}
              </Box>
            )}
            
            {/* User Section */}
            {!isMobile && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: 2,
                  transition: 'opacity 0.3s ease', // Smooth opacity transition
                }}
              >
                {currentUser ? (
                  <>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        mr: 2,
                        transition: 'color 0.3s ease', // Smooth color transition
                        '&:hover': {
                          color: theme.palette.secondary.main, // Change color on hover
                        },
                      }}
                    >
                      {currentUser.displayName}
                    </Typography>
                    <IconButton
                      color="inherit"
                      component={LogoutComponent}
                      aria-label="logout"
                      sx={{
                        transition: 'transform 0.3s ease', // Smooth transform transition
                        '&:hover': {
                          transform: 'scale(1.2)', // Scale up on hover
                        },
                      }}
                    >
                      <LogoutIcon />
                    </IconButton>
                  </>
                ) : (
                  <Button
                    color="inherit"
                    component={Link}
                    to="/login"
                    startIcon={<LoginIcon />}
                    sx={{
                      transition: 'background-color 0.3s ease, color 0.3s ease', // Smooth background transition
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.main, // Change background on hover
                        color: '#000000', // Change text color on hover
                      },
                      ml: 2, // Add left margin for spacing
                    }}
                  >
                    Login
                  </Button>
                )}
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </Fade>
      {/* Mobile Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.primary.main,
            color: '#ffffff',
            transition: 'background-color 0.3s ease', // Smooth background transition
          },
        }}
      >
        <Box
          sx={{ width: 250, p: 2 }}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          <List>
            {menuItems.map((item) =>
              item.auth ? (
                currentUser && (
                  <ListItem
                    button
                    key={item.text}
                    component={Link}
                    to={item.link}
                    sx={{
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                      },
                      mb: { xs: 1.5, sm: 2 }, // Increased bottom margin
                    }}
                  >
                    <IconButton color="inherit">
                      {item.icon}
                    </IconButton>
                    <ListItemText primary={item.text} />
                  </ListItem>
                )
              ) : (
                <ListItem
                  button
                  key={item.text}
                  component={Link}
                  to={item.link}
                  sx={{
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                    },
                    mb: { xs: 1.5, sm: 2 }, // Increased bottom margin
                  }}
                >
                  <IconButton color="inherit">
                    {item.icon}
                  </IconButton>
                  <ListItemText primary={item.text} />
                </ListItem>
              )
            )}
            {/* Theme Toggle in Drawer */}
            <ListItem
              button
              onClick={toggleTheme}
              sx={{
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.main,
                },
                mb: { xs: 1.5, sm: 2 }, // Increased bottom margin
              }}
            >
              <IconButton color="inherit">
                {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              <ListItemText primary="Toggle Theme" />
            </ListItem>
            {/* User Section in Drawer */}
            <ListItem sx={{ mb: { xs: 1.5, sm: 2 } }}> {/* Increased bottom margin */}
              {currentUser ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      mr: 2,
                      transition: 'color 0.3s ease',
                      '&:hover': {
                        color: theme.palette.secondary.main,
                      },
                    }}
                  >
                    {currentUser.displayName}
                  </Typography>
                  <IconButton
                    color="inherit"
                    component={LogoutComponent}
                    aria-label="logout"
                    sx={{
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.2)',
                      },
                    }}
                  >
                    <LogoutIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  color="inherit"
                  component={Link}
                  to="/login"
                  startIcon={<LoginIcon />}
                  sx={{
                    width: '100%',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: theme.palette.secondary.main,
                      color: '#000000',
                    },
                    mb: { xs: 1.5, sm: 2 }, // Increased bottom margin
                  }}
                >
                  Login
                </Button>
              )}
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
}

export default NavBar;
